.linkList{
    padding: 40px 0;
    &__title{
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 10px;
    }
    &__content{
        span{
            &.icon{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                img{
                    width: 12px;
                    height: auto;
                }
            }
        }
        ul{
            list-style: none;
            li{
                font-size: 1.4em;
                font-weight: 700;
                border-top: 2px solid $bckGrey;
                padding: 15px 0;
                position: relative;

                a{                
                    display: flex;
                    justify-content: space-between;
                    color: $black;
                    padding-left: 20px;
                    padding-right: 20px;
                    img{
                        transform: rotate(180deg);
                        display: block;
                        width: 24px;
                        height: 16px;
                       }
                    @include transition(0.3s);
                    &:hover{
                        color: $yellow;
                    }
                }
            }
        }
    }
    &__img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}