.home-link{
  list-style: none;
  font-size: 1rem;

  &--item{
    &:before{
      position: relative;
      top: 2px;
      content: " ";
      display: inline-block;
      width: 15px;
      height: 1rem;
      margin-right: 0.3rem;
      background: $green;
    }
  }
  &--date{

  }
  &--url{
    color: $black;
    font-weight: bold;
    &:hover{
      color: $green;
    }
  }
}

.notifications-box{
  @media screen and (min-width:1023px) {
    position: relative;
    right: 80px;
  }
}