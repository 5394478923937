// colors
$white: #fff;
$black: #000;
$yellow: #ffdc00;
$lightGray: #e5e5e5;
$darkGray: #3d3d3f;
$bckGrey: #dcdcdc;
$bgLightGray: #f9f9f9;
$lineMenu: #efefef;
$bgMenu: $bgLightGray;
$footer: $darkGray;
$bar: $darkGray;
$yellowLight: #faec98;
$red: #f60000;
$txtGrey: #636264;
$accordionBorder: #d2d2d2;
$green: #80c31c;

$gray1-color: #f2f2f2;
$gray2-color: #c2c2c2;
$gray3-color: #e5e5e5;

//pretty
$pretty--color-default:$black;
$pretty--color-primary:$black;

//zwariowane kolorki
$crazyColors: (
    crazy-1: #E3A413,
    crazy-2: #E5531A,
    crazy-3: #6F7D1E,
    crazy-4: #C4006E,
    crazy-5: #CE1431,
    crazy-6: #219CDC
);

// viewport
$res1440: 'all and (max-width: 1440px)';
$res1300: 'all and (max-width: 1300px)';
$res1200: 'all and (max-width: 1199px)';
$res1100: 'all and (max-width: 1100px)';
$res: 'all and (max-width: 1024px)';
$res992: 'all and (max-width: 991px)';
$medium: 'all and (max-width: 767px)';
$res840: 'all and (max-width: 840px)';
$res650: 'all and (max-width: 649px)';
$res550: 'all and (max-width: 549px)';
$res440: 'all and (max-width: 439px)';
$mobileLand: 'all and (max-height: 480px) and (max-width: 767px)';
$mobileLarge:  'all and (max-width: 580px)';
$mobile: 'all and (max-width: 480px)';
$xsmall: 'all and (max-width: 320px)';

//fonts
@font-face {
    font-family: 'Roboto';
    src: local('Roboto-Light'),
        url("../../../fonts/Roboto-Light.ttf") format('truetype'),
        url("../../../fonts/Roboto-Light.woff") format('woff'),
        url("../../../fonts/Roboto-Light.svg") format('svg'),
        url("../../../fonts/Roboto-Light.eot");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto-Regular'),
        url("../../../fonts/Roboto-Regular.ttf") format('truetype'),
        url("../../../fonts/Roboto-Regular.woff") format('woff'),
        url("../../../fonts/Roboto-Regular.svg") format('svg'),
        url("../../../fonts/Roboto-Regular.eot");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto-Medium'),
        url("../../../fonts/Roboto-Medium.ttf") format('truetype'),
        url("../../../fonts/Roboto-Medium.woff") format('woff'),
        url("../../../fonts/Roboto-Medium.svg") format('svg'),
        url("../../../fonts/Roboto-Medium.eot");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto-Bold'),
        url("../../../fonts/Roboto-Bold.ttf") format('truetype'),
        url("../../../fonts/Roboto-Bold.woff") format('woff'),
        url("../../../fonts/Roboto-Bold.svg") format('svg'),
        url("../../../fonts/Roboto-Bold.eot");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto-LightItalic'),
        url("../../../fonts/LightItalic.ttf") format('truetype'),
        url("../../../fonts/LightItalic.woff") format('woff'),
        url("../../../fonts/LightItalic.svg") format('svg'),
        url("../../../fonts/LightItalic.eot");
    font-style: italic;
    font-weight: 100;
}
