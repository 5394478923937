.contact {
  h2 {
    font-weight: 700;
    font-size: 3em;
    &::after {
      content: "";
      display: block;
      border-bottom: 1px solid $accordionBorder;
      padding-top: 25px;
    }
  }
  h5.sub-header {
    margin-top: -22px;
    position: relative;
    display: block;
    font-weight: normal;
    font-weight: bold;
    font-size: 2em;
  }
  &__fsLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    p {
      font-weight: 300;
      font-size: 1.8em;
      line-height: 2em;
      margin-bottom: 0;
      a {
        font-weight: 700;
        color: $black;
        @include transition(0.3s);
        &:hover {
          color: $yellow;
        }
      }
    }
  }
  &__fsRight {
    display: flex;
    justify-content: space-around;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    & > div {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    @media #{$mobile} {
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    img {
      width: auto;
      max-width: 100%;
      display: block;
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
    }
    p {
      text-align: center;
      font-weight: 700;
      font-size: 1.8em;
      margin-top: 20px;
      span {
        font-weight: 300;
        display: block;
      }
    }
  }
  &__secondSection {
    padding-bottom: 40px;
    display: flex;
    align-items: flex-start;
    @media #{$res840} {
      padding-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    img {
      width: auto;
    }
    div {
      margin-left: 20px;
      width: 100%;
      @media #{$res840} {
        margin-left: 0;
      }
      p {
        margin-bottom: 25px;
        &:nth-child(1) {
          font-size: 1.4em;
          font-weight: 300;
          margin-top: 50px;
          &::after {
            content: "";
            display: block;
            padding-top: 5px;
            border-bottom: 1px solid $accordionBorder;
          }
        }
        &:nth-child(1) {
          font-size: 1.8em;
          font-weight: 700;
          a {
            color: $black;
            @include transition(0.4s);
            &:hover {
              color: $yellow;
            }
          }
        }
        span {
          display: block;
        }
      }
      &.countryList {
        margin: 0;
        width: 100%;
        p {
          margin: 0;
          font-weight: bold;
          border: none;
          font-size: 1.6em;
          &:after {
            border: none;
            padding: 0;
          }
        }
        ul {
          li {
            font-weight: 400;
            align-items: baseline;
          }
        }
      }
    }
  }
  .mail {
    margin-top: -20px;
    font-size: 1.4em;
    a {
      color: $black;
      transition: 0.3s;
      &:hover {
        color: $yellow;
      }
    }
  }
}

#contact-map {
  width: 80%;
  @media #{$medium} {
    width: 100%;
  }
  .cls-1 {
    fill: #bcbcbc;
  }

  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6 {
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.5px;
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: #ddd;
  }

  .cls-3 {
    fill: #a3a3a3;
  }

  .cls-4 {
    fill: #f4f4f4;
  }

  .cls-5 {
    fill: #eaeaea;
  }

  .cls-6 {
    fill: #7f7f7f;
  }
  #g1,
  #g2,
  #g3,
  #g4,
  #g5,
  #g6 {
    &.active {
      .cls-1,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6 {
        fill: $yellow;
        opacity: 1;
      }
    }
  }
}

.contact-map {
  padding-top: 100px;
  padding-bottom: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$res992} {
    padding: 0;
  }
}

.mapInfo {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media #{$res992} {
    position: relative;
  }
  &__img {
    z-index: 1;
    &--left {
      margin-right: -30px;
    }
    &--right {
      margin-left: -30px;
    }
  }
  &__contact {
    background: $darkGray;
    width: 300px;
    padding: 10px 0;
    text-align: left;
    &--left {
      padding-left: 40px;
    }
    &--right {
      padding-right: 40px;
      padding-left: 20px;
    }
    p {
      color: $white;
      font-size: 1.4em;
      font-weight: 700;
      margin-bottom: 0;
      a {
        color: $white;
        transition: 0.3s;
        &:hover {
          color: $yellow;
        }
        &.accLink {
          color: $yellow;
          text-decoration: underline;
          font-size: 0.85em;
        }
      }
    }
  }
}

#t-korolczuk {
  top: 0;
  right: 30%;
  @media #{$res992} {
    top: initial;
    right: initial;
    margin: 20px 0;
  }
}

#d-wysocki {
  top: 30%;
  left: 0%;
  @media #{$res992} {
    top: initial;
    left: initial;
    margin: 20px 0;
  }
}

#j-buczek {
  bottom: 20%;
  left: 2%;
  @media #{$res992} {
    bottom: initial;
    left: initial;
    margin: 20px 0;
  }
}

#k-otomanski {
  right: 5%;
  top: 35%;
  @media #{$res992} {
    top: initial;
    right: initial;
    margin: 20px 0;
  }
}

#m-borowka {
  right: 0;
  top: 65%;
  @media #{$res992} {
    top: initial;
    right: initial;
    margin: 20px 0;
  }
}

#d-klimeczko {
  bottom: 0;
  right: 10%;
  @media #{$res992} {
    bottom: initial;
    right: initial;
    margin: 20px 0;
  }
}

.accordionList {
  margin-top: 80px;
  .accordion {
    background: none;
    padding: 0;
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 600px;
      padding: 0 25px;
      @media #{$res550} {
        height: 1000px;
        li {
          font-size: 1.2em;
        }
      }
    }
  }
}

.contactBox {
  display: none;
  &.active {
    display: block;
  }
  ul {
    list-style-type: none;
    font-weight: 500;
    margin: 0 0 0 15px;
    li {
      margin-left: 10px;
      font-size: 1.6em;
      &::before {
        content: "\25CF";
        font-size: 1rem;
        display: inline-block;
        width: 20px;
        margin-left: -20px;
        color: $yellow;
      }
    }
  }
}

.list-data__location {
  &--name {
    font-weight: 700;
    font-size: 2.5em;
    &::after {
      content: "";
      display: block;
      border-bottom: 1px solid $accordionBorder;
      padding-top: 25px;
    }
  }
  &--content {
    h3 {
      font-size: 2em;
      margin-bottom: 1em;
      margin-top: 2em;
    }
    p {
      margin-bottom: 1em;
    }
    ul {
      font-size: 1.6em;
      li {
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          background: #000;
          display: inline-block;
          left: 0.25em;
          top: 0.55em;
        }
      }
    }
  }
}
