@mixin makeItcolor($color){
    .boxOnBar{
        &__content{
            &.active{
                a{
                    div{
                        background:$color;
                        &::after{
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 15px 15px 0 15px;
                            border-color: $color transparent transparent transparent;
                            position: absolute;
                            left: 50%;
                            bottom: -15px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
        &__contentImg{
            &.active{
                h4{
                    background:$color;
                    color: $white;
                    &::after{
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 15px 15px 0 15px;
                        border-color: $color transparent transparent transparent;
                        position: absolute;
                        left: 50%;
                        bottom: -15px;
                        transform: translateX(-50%);
                    }
                }
            }
            span{
                background:$color;
            }
        }
    }
    .box-icon{
        .cls-1 {
            fill: $color;
        }
    }
    .box{
        .item{
            span{
                &.icon{
                    background: $color;
                }
            }
        } 
    }
    .linkList{
        &__title{
            color: $color;
        }
        &__content{
            span.icon{
                background: $color;
            }
        }
    }
    span.ico{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        background: $color;
        position: absolute;
        left: 16px;
    }
    .imgbox-box{
        .textBox{
            background-color: $color;
        }
    }
    .contentBox{
        a{
            color: $color;
            &:hover{
                color: $darkGray;
                text-decoration: underline;
            }
        }
    }
}


@for $i from 1 through 6{
    .categoryColor-#{$i}{
        @include makeItcolor(map-get($crazyColors, crazy-#{$i})); 
    } 
}