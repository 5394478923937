.contact-info {
  display: none;
  position: absolute;
  width: 440px;
  right: 55px;
  top: 0;
  padding-right: 30px;

  &.active {
    display: block;
  }

  &__top {
    position: relative;
    background: $black;
    color: $white;
    height: 55px;
    font-weight: 700;
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 0 9px 14px;
      border-color: transparent transparent transparent $black;
      position: absolute;
      right: -14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__middle {
    background: $white;
    box-shadow: 0px 0px 14.88px 1.12px rgba($black, 0.19);
    text-align: center;
    padding: 20px 5px;

    h4 {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      line-height: 1.8;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      color: $darkGray;
      margin: 0;
    }
  }

  &__img {
    width: 74px;
    height: 74px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin: 20px auto 10px;
  }

  &__boxes {
    display: flex;
  }

  &__box {
    h5 {
      font-size: 14px;
      font-weight: 700;
      color: $darkGray;
      margin: 0 0 0.5rem 0;
      white-space: nowrap;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: $darkGray;
      padding: 0 20px;
    }
  }

  &__bottom {
    background: $darkGray;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 300;
      color: $white;
      margin: 0;

      a {
        color: $white;
        font-weight: 700;
        transition: 0.3s;

        &:hover {
          color: $yellow;
        }
      }
    }
  }
}
