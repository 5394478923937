.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background: $white;
    height: 114px;
    box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.15);
    z-index: 99999;
    @media #{$medium}{
        height: 185px;
    }
    &--intro{
        height: 114px;
    }
    .container,
    .row {
        height: 100%;
        width: 100%;
    }
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        @media #{$res992}{
            flex-direction: column;
            align-items: inherit;
            height: auto;
        }
        &--intro{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }
        &List{
            display: flex;
            @media #{$medium}{
                flex-direction: column;
                align-items: flex-end;
                margin-top: 10px;
            }
            &__sections {
                display: flex;
                list-style: none;
                margin: 0;
                @media #{$medium}{
                    margin-top: 10px;
                }
                li {
                    width: 42px;
                    height: 42px;
                    border: 1px solid $lightGray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    cursor: pointer;
                    svg {
                        height: 32px;
                        &.active{
                            .cls-3, .cls-4 {
                                fill: #fd0;
                            }
                        }
                    }
                    span{
                        position: absolute;
                        bottom: -10px;
                        display: none;
                        background: $black;
                        color: $white;
                        font-weight: 700;
                        font-size: 1.6em;
                        padding: 5px 12px;
                        @media #{$res992}{
                            bottom: -35px;
                        }
                        @media #{$medium}{
                            bottom: 15px;
                            z-index: 5;
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 10px 10px 10px;
                            border-color: transparent transparent $black transparent;
                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    &:hover{
                        span{
                            display: block;
                        }
                    }
                    &.active,
                    &.active-tooltip{
                        svg{
                            .cls-3, .cls-4 {
                                fill: #fd0;
                            }
                        }
                    }
                }
            }
            &__options{
                display: flex;
                list-style: none;
                margin: 0;
                @media #{$medium}{
                    margin-top: 10px;
                }
                li {
                    width: 42px;
                    height: 42px;
                    border: 1px solid $lightGray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    &:nth-child(1) {
                        width: 218px;
                        position: relative;
                        form#search{
                            width: 100%;
                            height: 100%;
                            input {
                                width: 100%;
                                height: 100%;
                                border: none;
                                padding-left: 15px;
                                font-size: 1.6em;
                                &::-webkit-input-placeholder {
                                    font-weight: 400;
                                    // font-size: 1.6em;
                                    color: $lightGray;
                                }
                                &::-moz-placeholder {
                                    font-weight: 400;
                                    // font-size: 1.6em;
                                    color: $lightGray;
                                }
                                &:-ms-input-placeholder {
                                    font-weight: 400;
                                    // font-size: 1.6em;
                                    color: $lightGray;
                                }
                                &:-moz-placeholder {
                                    font-weight: 400;
                                    // font-size: 1.6em;
                                    color: $lightGray;
                                }
                            }
                        }
                        img{
                            position: absolute;
                            right: 10px;
                        }
                    }
                    &:nth-child(3),
                    &:nth-child(2) {
                        background: $yellow;
                        border: 1px solid $yellow;
                    }
                }
            }
        }
        .menu {
            position: relative;
            margin-top: 2px;
            width: 28px;
            height: 20px;
            border: 0;
            border-top: 3px solid $black;
            background-color: transparent;
            transition: 0.4s;
            cursor: pointer;
            outline: none;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                border-top: 3px solid $black;
                transform: translateY(5px);
            }
            &:after {
                transform: translateY(13px);
                transition: 0.4s transform linear;
            }
            &--open {
                transform: rotate(45deg) translateY(0px);
                border: none;
                &::after {
                    transform: rotate(-90deg) translateX(-10px);
                }
            }
        }
    }
}

.navigation{
    display: none;
    width: 100%;
    height: calc(100vh - 114px);
    overflow: auto;
    background: $bgMenu;
    padding: 40px;
    h2{
        margin: 30px;
    }
    &__content{
        display: flex;
    }
    &__list{
        list-style: none;
        border-left: 2px solid $lineMenu;
        width: 33%;
        &--default,
        &--one,
        &--two,
        &--three{
            font-family: 'Roboto';
            li{
                padding: 0 50px 0 20px;
                font-size: 1.6em;
                font-weight: 300;
                margin: 15px 0;
                position: relative;
                &.arrow{
                    &::after{
                        content: '';
                        border: solid black;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(-45deg) translateY(-50%);
                        position: absolute;
                        right: 20px;
                        top: 50%;
                    }
                }
                a{
                    color: $black;
                    transition: 0.3s;
                    font-weight: 300;
                    // text-transform: lowercase;
                    padding: 0;
                    display: block;
                    &::first-letter{
                        // text-transform: uppercase;
                    }
                    &:hover{
                        color: $yellow;
                    }
                }
                button{
                    color: $black;
                    transition: 0.3s;
                    outline: none;
                    // text-transform: lowercase;
                    text-align: left;
                    padding: 0;
                    font-weight: 300;
                    width: 110%;
                    &::first-letter{
                        // text-transform: uppercase;
                    }
                    &:hover{
                        color: $yellow;
                    }
                }
                &.active{
                    position: relative;
                    color: $yellow;
                    font-weight: 700;
                    &::before{
                        content: "\25CF";
                        position: absolute;
                        left: -9px;
                        top: -10px;
                        font-size: 1.6em;
                        display: inline-block;
                        color: $yellow;
                    }
                    button{
                        color: $yellow;
                        font-weight: 700;
                    }
                }
            }
        }
        &--one{
            li{
                button{
                    font-weight: 700;
                }
            }
        }
        &--default{
            li{
                a{
                    color: $black;
                    transition: 0.3s;
                    font-weight: 300;
                    text-transform: none;
                    padding: 0;
                    display: block;
                    &::first-letter{
                        text-transform: none;
                    }
                    &:hover{
                        color: $yellow;
                    }
                }
            }
        }
    }
}