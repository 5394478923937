//transition
//default usage @include transition(all .5s ease);

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
  } 

  //button hover
  @mixin btnColor($colorName,$colorContra:"") {
    border:2px solid transparent;
    border-radius:0;
    text-align:center;
    padding:7px 0;
    width:100%;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    font-size:1.4em;
    @include transition(all .5s ease);
    @include changeColor($colorName, $colorContra); 
}

@mixin changeColor($color,$contra){
    @if $contra != ""{
        background-color: $color;
        color:$contra;
        &:hover{
            cursor: pointer;
            border:1px solid $color;
            background: $contra;
            color:$color;
        } 
    }@else{
        background-color: $color;
        color:$white;
        &:hover{
            cursor: pointer;
            border:1px solid $color;
            background: $white;
            color:$color;
        } 
    } 
}

@mixin linkHover($color){
    background: $color;
    border: 1px solid $color;
    @include transition(0.3s);
    &:hover{
        background: $white;
        color: $color;
    }
}

//box shadow
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
  }