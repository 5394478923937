.appBox{
    background-color:$bgMenu;
    padding:60px 0;
    &__main{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item{
            width:32%;            
            @media #{$res}{
                width:45%;
            }
            @media #{$mobile}{
                width:100%;
            }
            &--2{
                width: 49%;
                @media #{$res}{
                    width:45%;
                }
                @media #{$mobile}{
                    width:100%;
                }
            }
            background-color:$white;
            padding:25px;
            -webkit-box-shadow: 0px 0px 40px 0 $bckGrey ;
            -moz-box-shadow: 0px 0px 40px 0 $bckGrey ;
            box-shadow: 0px 0px 40px 0 $bckGrey;
            margin-bottom: 20px;
            margin-top:0;
            position: relative;
            @include transition(all .1s ease);
            //szalone kolorki
            @for $i from 1 through 6{
                &:nth-child(#{$i}) .btnBox div{
                    background-color: map-get($crazyColors, crazy-#{$i});
                }
            }
            img{
                margin:0 auto;
                text-align:center;
                display:block;
            }
            svg{
                height: 155px;
                margin: 0 auto;
                display: block;
            }
            &:hover{
                margin-top:-10px;
                svg{
                    .cls-3, .cls-4 {
                        fill: #fd0;
                    }
                }
            }
            .btnBox{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    height:2px;
                    background-color:$bckGrey;
                    width:100%;
                    margin-right:5px;
                }
                div{
                    background-color: map-get($crazyColors, brown);
                    padding:14px 11px;
                    &.regular{
                        background-color: $yellow;
                    }
                }
            }
            h5{
                margin-top:12px;
                font-weight:400;
                font-size: 1.6em;
                color:$txtGrey;
                text-align:center;
                text-transform: uppercase;
            }
        }
    }
}

