.contentBox{
.libraryDownload{
    &__list{
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1em;
            &::before {
                content: "";
                display: none;
            }
            p{
                font-weight: 300;
                margin-bottom: 10px;
                span{
                    font-weight: 700;
                    width: 60px;
                    display: inline-block;
                }
            }
            a{
                color: $white;
                padding: 3px 20px;
                font-size: 1.2em;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 10px;
                &.black{
                    @include linkHover($black);
                }
                &.yellow{
                    @include linkHover($yellow);
                }
                &.gray{
                    @include linkHover($darkGray);
                }
            }
        }
    }
}
}

.libraryDownload{
    &__list{
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1em;
            &::before {
                content: "";
                display: none;
            }
            p{
                font-weight: 300;
                margin-bottom: 10px;
                span{
                    font-weight: 700;
                    width: 60px;
                    display: inline-block;
                }
            }
            a{
                color: $white;
                padding: 3px 20px;
                font-size: 1.2em;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 10px;
                &.black{
                    @include linkHover($black);
                }
                &.yellow{
                    @include linkHover($yellow);
                }
                &.gray{
                    @include linkHover($darkGray);
                }
            }
        }
    }
}