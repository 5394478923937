.svgBox{
    .cls-1 {
        fill: #dadada;
      }

      .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 0.75px;
      }

      .cls-3, .cls-4 {
        fill: #dadada;
        transition: 0.3s;
      }

      .cls-4 {
        opacity: 0.65;
      }

      .cls-4, .cls-5, .cls-6, .cls-7 {
        isolation: isolate;
      }

      .cls-5, .cls-6, .cls-7 {
        opacity: 0.1;
      }

      .cls-5 {
        fill: url(.gradient_1);
      }

      .cls-6 {
        fill: url(.gradient_2);
      }

      .cls-7 {
        fill: url(.gradient_3);
      }
}