.boxOnBar{
    display: flex;
    flex-wrap: wrap;
    margin: -140px 0 40px 0;
    &__content{
        width: 100%;
        a{
            &>div{
                position: relative;
                background: $white;
                height: 220px;
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
                @include transition(0.6s);
                h4{
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 1.6em;
                    line-height: 1.6em;
                    font-weight: 400;
                    color: $black;
                    padding: 0 10%;
                    width: 100%;
                    -webkit-font-smoothing: antialiased;
                    backface-visibility: hidden;
                    transform: perspective(1px) scale(1) translateZ(0)
                }
                &:hover{
                    background: $gray1-color;
                }
            }
        }
        &.active{
            a{
                div{
                    h4{
                        color: $white;
                    }
                }
            }
        }
    }
    &__contentImg{
        width: 100%;
        background: $white;
        height: 220px;
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.08);
        margin-bottom: 25px;
        @include transition(0.6s);
        a{
            @include transition(0.6s);
            div{
                background: $white;
                height: 220px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                div{
                    position: relative;
                    background: $white;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    text-align: center;
                    span{
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        display: block;
                        position: absolute;
                        top: 30px;
                        left: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media #{$res1200}{
                            left: 45px;
                        }
                        @media #{$res992}{
                            left: 15px;
                        }
                        @media #{$medium}{
                            left: 55px;
                        }
                        @media #{$res550}{
                            left: 5%;
                        }
                    }
                    svg{
                        height: 120px;
                        .cls-3, .cls-4 {
                            fill: #fd0;
                        }
                    }
                }
            }
            h4{
                text-transform: uppercase;
                font-size: 1.5em;
                font-weight: 400;
                color: $black;
                padding: 0 10%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-bottom: 0;
                position: relative;
                transition: 0.3s;
            }
        }
        &:hover{
            h4{
                background: $gray1-color;
            }
        }
    }
    &__img{
        flex-direction: column;
        div{
            width: 100%;
            text-align: center;
            height: 150px;
        }
    }
    &__icon{
        width: auto !important;
    }
    &--el2{
        .boxOnBar__content,
        .boxOnBar__contentImg{
            width: calc(50% - 20px);
            margin: 0 10px;
            @media #{$medium}{
                width: 50%;
                width: calc(50% - 20px);
            }
            @media #{$mobileLarge}{
                width: 100%;
            }
        }
        &.owl-carousel{
            .boxOnBar__content,
            .boxOnBar__contentImg{
                width: 100%;
                margin: 0 0 25px 0;
            }
        }
    }
    &--el3{
        .boxOnBar__content,
        .boxOnBar__contentImg{
            width: calc(33.33% - 20px);
            margin: 0 10px;
            @media #{$medium}{
                width: 50%;
                width: calc(50% - 20px);
            }
            @media #{$mobileLarge}{
                width: 100%;
            }
        }
        &.owl-carousel{
            .boxOnBar__content,
            .boxOnBar__contentImg{
                width: 100%;
                margin: 0 0 25px 0;
            }
        }
    }
    &--el4{
        .boxOnBar__content,
        .boxOnBar__contentImg{
            width: calc(25% - 20px);
            margin: 0 10px;
            @media #{$medium}{
                width: calc(50% - 20px);
            }
            @media #{$res550}{
                width: 100%;        
            }
        }
        &.owl-carousel{
            .boxOnBar__content,
            .boxOnBar__contentImg{
                width: 100%;
                margin: 0 0 25px 0;
            }
        }
    }
    &.owl-carousel .owl-nav button.owl{
        &-prev,
        &-next{
            font-size: 7rem;
            color: $yellow;
            position: absolute;
            top: 0;
            outline: none;
        }
        &-prev{
            left: -2.6vw;
        }
        &-next{
            right: -2.6vw;
        }
    }
}