.ie-popup {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 96%;
  @media (min-width: 576px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 960px;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background: $yellow;
    &::before, 
    &::after {
      content: ' ';
      position: absolute;
      top: 5px;
      left: 19px;
      height: 30px;
      width: 2px;
      background-color: white;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &__content {
    padding: 1.5rem 2.5rem;
    @media (min-width: 576px) {
      padding: 5rem;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}

.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999998;
  background: rgba($black, .6);
}