.bar{
    background: $bar;
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 114px;
    z-index: 100;
    margin-bottom: 20px;
    @media screen and (max-width:767px) {
        top: 185px;
    }
    &__content{
        width: 100%;
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a{
            color: $white;
            font-size: 1.5em;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            width: 100%;
            position: relative;
            @include transition(0.3s);
            padding-right: 35px;
            &::after{
                content: url('../../../../img/link-arrow.png');
                transform: rotate(180deg);
                position: absolute;
                height: 20px;
                width: 24px;
                right: 0;
            }
            &:hover{
                color: $yellow;
            }
            &.return{
                padding-left: 40px;
                &::before{
                    content: url('../../../../img/link-arrow.png');
                    position: absolute;
                    height: 20px;
                    width: 24px;
                    margin-left: -40px;
                }
                &::after{
                    content: '';
                }
            }
        }
        hr{
            background: $white;
            height: 2px;
            margin: 12px 0;
        }
    }
    &--h160{
        position: static;
        margin-bottom: 0;
        .bar__content{
            padding-bottom: 160px;
        }
    }
    &--contact{
        position: relative;
        top: 36px;
        margin-bottom: 0;
    }
}