.imgbox{
    margin: 40px 0;
    .imgbox-box{
        display: flex;
        &.bottom{
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            margin-top:20px;
            margin-bottom:30px;
            .img-content{
                max-width: 100%;
            }
            .textBox{
                p{
                    margin:0;
                    font-weight: 100;
                    color: white;  
                    padding:25px 20px;     
                    span{
                        font-weight: 700;
                    }      
                    a{
                        color: white;
                        transition: 0.3s;
                        font-weight: 100;
                        &:hover{
                            color: $darkGray;
                            text-decoration: underline;
                        }
                    }  
                }
            }
        }
        &.left{
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            height: 100%;
            // margin-top:20px;
            // margin-bottom:30px;
            @media #{$res992}{
                flex-direction: column;
            }            
            .img-content{
                @media #{$res992}{
                    width: 100%;
                    height: auto;
                }
            }
            .textBox{
                // padding-left:20px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                height: 100%;
                @media #{$res992}{
                    width: 100%;
                }
                p{
                    margin:0;
                    font-weight: 100;
                    color: white;     
                    padding: 25px 20px;
                    i{
                        font-style:normal !important;
                    }  
                    span{
                        display: block;
                        font-weight: 700;
                    }  
                    a{
                        color: white;
                        transition: 0.3s;
                        font-weight: 100;
                        &:hover{
                            color: $darkGray;
                            text-decoration: underline;
                        }
                    }        
                }
            }
        }
        .img-content{
            text-align: center;
            img{
                max-height: 400px;
                cursor: zoom-in;
            }
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
}

.widget-graphic{
    margin: 10px 0;
}