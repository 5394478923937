.privacyPolice .contentBox{
    h2{
        margin-bottom: 50px;
    }
    h3{
        text-align: center;
        margin-bottom: 25px;
    }
    p{
        line-height: 1.6;
    }
    ol{
        line-height: 1.6;
        padding-left: 25px;
        font-family: 'Roboto';
        li{
            font-size: 1.6em;
            padding-left: 10px;
            ol{
                list-style-type: lower-alpha;
                li{
                    font-size: 1em;
                }
            }
            ul{               
                padding-left: 25px;
                li{
                    font-size: 1em;
                }
            }
        }
    }
    a{
        color: $black;
        transition: 0.3s;
        &:hover{
            color: $yellow;
        }
    }
}