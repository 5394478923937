*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-size: 62.5% !important;
  font-family: "Roboto";
  min-height: 100vh;
}

p {
  font-family: "Roboto";
  font-size: 1.6em;
  font-weight: 400;
  margin-bottom: 35px;
}

.content {
  padding-top: 114px;
  @media #{$medium} {
    padding-top: 185px;
  }
}

h2 {
  font-weight: bold;
  font-size: 4em;
  margin-top: 30px;
  margin-bottom: 40px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

//tooltip
.tooltip-inner {
  border-radius: 0;
}

.adminDataInfo {
  &--mt {
    margin-top: 80px;
  }
  p {
    font-size: 1.2em;
    a {
      display: initial;
      font-weight: 400;
      transition: 0.3s;
      font-size: 1em;
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.hideOverflow {
  overflow: hidden;
}

.hasIco {
  display: flex;
  align-items: center;
  margin-left: 46px;
}

.mb-60 {
  margin-bottom: 60px;
}

caption {
  caption-side: top;
  font-style: italic;
}

.logo {
  width: 200px;
  &-wrapper {
    @media #{$res1200} {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  &-footer {
    position: absolute;
    width: 100px;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
    @media #{$res1200} {
      position: relative;
      transform: none;
      right: initial;
      top: initial;
      margin: 40px 0;
      display: block;
    }
    &-left {
      position: absolute;
      left: 2vw;
      top: 50%;
      transform: translateY(-50%);
      width: 120px;
      @media #{$res1200} {
        position: relative;
        transform: none;
        right: initial;
        top: initial;
        margin: 40px;
        display: block;
      }
    }

    &-right {
      position: absolute;
      right: 2vw;
      top: 50%;
      transform: translateY(-50%);
      width: 33%;
      text-align: right;
      img {
        margin-left: 15px;
        @media #{$res1200} {
          margin: 10px;
        }
      }
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      .logo-footer-isover {
        width: 100px;
      }

      .logo-footer-rigips {
        width: 113px;
      }
      .logo-footer-weber {
        width: 114px;
      }

      @media #{$res1200} {
        position: relative;
        transform: none;
        right: initial;
        top: initial;
        margin: 40px 0;
        display: flex;
        text-align: center;
        width: auto;
      }

      @media #{$res650} {
        flex: 0 0 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

#closeBtn {
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  background-color: $yellow;
  font-weight: bold;
  color: $black;
  padding: 5px 15px;
  font-size: 0.8em;
}
