.cookies{
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $black;
    color: $white;
    padding: 25px 0;
    z-index: 100;
    &__content{
        p{
            margin: 0;
            a{
                color: $white;
                transition: 0.3s;
                &:hover{
                    color: $yellow;
                }
            }
        }
    }
    &__btn{
        display: flex;
        justify-content: flex-end;
        button{
            border: 1px solid $yellow;
            background: $yellow;
            color: $black;
            padding: 5px 20px;
            transition: 0.4s;
            font-size: 1.6em;
            font-weight: 400;
            margin-top: 15px;
            &:hover{
                background: $black;
                color: $yellow;
            }
        }
    }
}