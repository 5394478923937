.search-page{
    .pagination{
        justify-content: flex-end;
        font-size: 1.6em;
        margin: 1em 0;
        >span{
            display: inline-block;
            padding: 0 0.5em;
        }
    }
    .searchResults{
        list-style: none;
    }
}