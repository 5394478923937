footer {
  background: $footer;
  padding: 55px 0;
  width: 100%;
  position: sticky;
  top: 100%;
  p {
    color: $white;
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    color: $gray2-color;
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    max-width: 40%;
    margin: 0 auto;

    @media #{$res992} {
      max-width: none;
    }
    li {
      a {
        color: $gray2-color;
        transition: 0.3s;
        &:hover {
          color: $yellow;
        }
      }
      &:after {
        content: "/";
        margin: 0 15px;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
}
