form{
    .form-group{
        margin-bottom:1.2rem;
        select,
        input{
            border-radius:0;
            border:0;
        }
        small{
            opacity:0;
            color:red;
            font-weight: bold;
            height:0;
            font-size: 1em;
            @include transition(all .5s ease);
        }
        &.error{
            small{
                opacity:1;
                height:100%;
            }
            input{
                border:1px solid red;
            }
        }
    }    
    .infoSpan{
        color:$black;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 7px;
        margin-top: -10px;
        display: block;
    }
    #login{
        @include btnColor($black);
    }
    #register{
        @include btnColor($red);
        margin-top:50px;
    }
    a{
        text-decoration: underline;
        color:$black;
        font-size: 1.2em;
        margin-top:20px;
        display: block;
        font-weight: bold;
    }
}