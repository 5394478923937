.toolbar{
    position: fixed;
    right: 0;
    top: 120px;
    z-index: 101;
    &__list{
        list-style: none;
        li{
            position: relative;
            width: 55px;
            height: 55px;
            background: $yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 4px 0;
            span{
                position: absolute;
                width: 240px;
                height: 55px;
                background: $black;
                right: 85px;
                color: $white;
                font-weight: 700;
                font-size: 1.6em;
                display: flex;
                justify-content: center;
                align-items: center;
                display: none;
                &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 0 9px 14px;
                    border-color: transparent transparent transparent $black;
                    position: absolute;
                    right: -14px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &:hover{
                span{
                    display: flex;
                }
                .contact-info {
                    display: block;
                }
            }
        }
    }
}