.breadcrumbs{
    list-style:none;
    display:flex;
    margin-top: 30px;
    margin-bottom: 40px;
    @media #{$mobileLarge}{
        flex-direction: column;
        flex-wrap: wrap;
    }
    &:after{
        width:60px;
        height:2px;
        background-color:$lightGray;
        content:'';
        position:absolute;
        bottom:15px;
        left:15px;        
    }
    li{
        line-height:1.6em;
        a{
            color:$black;
            font-size: 1.4em;
            @media #{$medium}{ 
                font-size: 1.2em;
            }
        }
        &:not(:last-child):after{
            content:'/';
            position: relative;
            margin:0 5px;
            font-size: 1.6em;
        }
        &:last-child{
            font-weight: bold;
        }
    }
}