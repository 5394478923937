#reset-password,
#editProfile,
#changePassword{
    input{
        border: 1px solid $gray3-color;
        &[type="submit"]{
            width: 100%;
            background: $black;
            color: white;
            border: 1px solid $black;
            height: 40px;
            font-size: 1.4em;
            font-weight: 700;
            transition: 0.4s;
            cursor: pointer;
            &:hover{
                background: transparent;
                color: $black;
            }
        }
    }
    select{
        border: 1px solid $gray3-color;
        background-image: url('../img/select-arrow.png');
        background-repeat: no-repeat;
        background-position: 98% 50%;
        background-size: 19px 19px;
        padding-right: 50px;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        &::-ms-expand {
            display: none;
        }
    }
}

.user{
    &-data{
        ul{
            list-style: none;
            li{
                font-size: 1.6em;
            }
        }
    }
    &-action{
        ul{
            list-style: none;
            li{
                font-size: 1.6em;
                a{
                    color: $black;
                    transition: 0.3s;
                    &:hover{
                        color: $yellow;
                    }
                }
            }
        }
    }
}

.backToProfile{
    font-size: 1.6em;
    font-weight: 700;
    color: $black;
    &:hover{
        color: $yellow;
    }
}

#reset-password{
    label{
        font-size: 1.6em;
    }
}