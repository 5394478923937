.contentBox{
    ul{
        list-style-type: none;
        font-weight: 400;
        margin: 0 0 15px 15px;
        li{
            margin-left: 10px;
            font-size: 1rem;
            &::before {
                content: "\25CF";
                font-size: 1em;
                display: inline-block;
                width: 20px;
                margin-left: -20px;
                color: $yellow;
                
            }
        }
    }
    ol{
        list-style-type: none;
        margin: 0 0 15px 0px;
        li{
            margin-left: 0px;
            font-size: 1rem;
            counter-increment: inst;
            &::before{
                content: counter(inst)  '.' ;
                font-size: 1em;
                display: inline-block;               
                margin-right: 5px;
               
            }
        }
    }

    table,
    li{
	font-size: 100%;
        a,p{
            font-size: 1rem;
        }
    }
    .libraryDownload__list li p{
            font-size: 1rem;
    }

    table {
	font-size: 100%;
        li {
            font-size: 1rem;
        }
    }

    p{
        font-family: 'Roboto';
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 10px;
        a{
            font-size: 1rem;
        }
    } 
    
    img{
        max-width: 100%;
        width: auto;
    }

    a{
        text-decoration: underline;
        cursor: pointer;
        transition: 0.3s;
        font-size: 1.6em;
        font-weight: 400;
    }

    b,
    strong{
        font-weight: 700;
    }
    .ytMovies{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin:0;
        li{
            width:31%;
            text-decoration:none;
            &:before{
                display:none;
            }
            a{
                text-decoration:none;
                position: relative;
                display: block;
                font-size:100%;
                &:after{
                    content:'';
                    background:url('/img/playBtn.png');
                    width: 78px;
                    height: 78px;
                    position: absolute;
                    top: calc(50% - 78px);
                    left: calc(50% - 39px);
                    text-decoration: none;
                    @media #{$medium}{
                        display:none;
                    }
                }
                &:hover{
                    text-decoration:none;                    
                    p{
                        color:$yellow;
                    }
                }
                .movie-title{
                    font-size: 1.4em;
                    color:$white;
                    background-color: $darkGray;
                    padding: 15px;
                    text-decoration: none;
                    @media #{$medium}{
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
