.accordion{
    background: $bgLightGray;
    padding: 40px 0;
    &__narrower{
        padding:10px 0;
    }
    &>div{
        margin-bottom: 20px;
    }
    &__header{
        font-size: 1em;
        [aria-expanded]{
            font-size: 2.2em;
            font-weight: 700;
            text-align: left;
            color: $black;
            outline: none;
            width: 100%;
            // border-bottom: 1px solid $accordionBorder;
            padding-bottom: 15px;
            position: relative;
            border-bottom: none;
            &::after{
                content: '';
                border: solid black;
                border-width: 0px 1px 1px 0;
                display: inline-block;
                padding: 12px;
                position: absolute;
                top: 18px;
                right: 60px;
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                @include transition(0.4s);
            }
            span{
                color: $yellow;
            }
        }
        [aria-expanded="false"]{
            &::after{
                content: '';
                top: 6px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }
    }
    &__content{
        padding: 30px 0;
    }

    button {
        text-decoration: none; 
        &:hover {
            text-decoration: none;
        }
    }
}