.pretty{
    margin-bottom:20px;
    &--longText{
        white-space:normal;
        input:checked ~ .state.p-primary label:after,
        &.p-toggle .state.p-primary label:after{
            top:0;
        }
        .state label:before, 
        .state label:after{
            top:auto;
        }
    
        .state{
            label{
                font-size: 1.2em;
                margin-left: 28px;
                text-align: left;
                text-indent: inherit;
                line-height:1.3em;
                a{
                    font-size: 100%;
                    line-height:1.3em;
                    display: inline;
                }
            }
        }
    }
}