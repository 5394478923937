.rodo .contentBox{
    h2{
        margin-bottom: 50px;
    }
    h3{
        font-size: 2em;
        margin: 40px 0 20px 0;
    }
    a{
        color: $black;
        transition: 0.3s;
        &:hover{
            color: $yellow;
        }
    }
    p{
        margin: 0;
        line-height: 1.6;
    }
    ol{
        line-height: 1.6;
        padding-left: 25px;
        font-family: 'Roboto';
        li{
            font-size: 1.6em;
            padding-left: 10px;
            ol{
                list-style-type: lower-alpha;
                li{
                    font-size: 1em;
                }
            }
            ul{
                list-style-type: disc;
                padding-left: 25px;
                li{
                    font-size: 1em;
                }
            }
        }
    }
}