.contentBox{
    .warning{
        .warningBox{
            padding:40px 50px;
            display:flex;
            margin-top:30px;
            margin-bottom:20px;
            justify-content: center;
            align-content: center;
            @media #{$medium}{
                flex-direction: column-reverse;
            }
            img{
                width: auto;
            }
            .iconBox{
                @media #{$medium}{
                    text-align: center;
                }
            }
            .textBox{
                margin-left:50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 90%;
                margin-right: 45px;
                @media #{$medium}{
                    margin-top: 30px;
                    margin-left:0;
                }
                h4{
                    font-size:1.6em;
                    font-weight: bold;
                    margin-bottom: 30px;
                }
                p{
                    margin:0;
                    font-weight: 400;
                }
            }
        }
    }
}

// .warning{
//     .warningBox{
//         padding:40px 50px;
//         display:flex;
//         margin-top:30px;
//         margin-bottom:20px;
//         justify-content: center;
//         align-content: center;
//         @media #{$medium}{
//             flex-direction: column-reverse;
//         }
//         img{
//             width: auto;
//         }
//         .textBox{
//             margin-left:50px;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             @media #{$medium}{
//                 margin-top: 30px;
//                 margin-left:0;
//             }
//             h4{
//                 font-size:1.6em;
//                 font-weight: bold;
//                 margin-bottom: 40px;
//             }
//             p{
//                 margin:0;
//                 font-weight: 300;
//             }
//         }
//     }
// }