table{
    width: 100%;
    font-size: 1.6em;
    margin-bottom: 10px;
    thead{
        font-weight: 700;
        border-bottom: 2px solid $black;
        th{
            padding: 10px;
            border-bottom: 1px solid black;
        }
    }
    tbody{
        tr{
            border-bottom: 1px solid $black;
        }
    }
    td{
        padding: 10px;
    }
    a{
        font-size: 1em;
    }
}