.navbar--noOptions{
    height:auto;
}
#intro{
    margin-top:150px;
    .top{
        background-color:$yellow;
        nav{
            .nav-tabs{
                background-color:$yellowLight;
                justify-content: center;
                border:none;
                .nav-link{
                    text-align: right;
                    padding:25px 0;
                    color:$black;
                    width:50%;
                    font-weight: bold;
                    border:none;
                    font-size: 2.2em; 
                    @include transition(all .5s ease);
                    &.active{
                        background-color:$yellow;
                    }
                    &:hover{
                        background-color:$gray1-color;
                    }
                    &:nth-child(even){
                        padding-left:160px;                        
                        text-align:left;
                    }
                    &:nth-child(odd){
                        padding-right:160px;
                    }
                    @media #{$mobileLarge}{
                        text-align:center;
                        &:nth-child(even){
                            padding-left:20px;                        
                            text-align:center;
                        }
                        &:nth-child(odd){
                            padding-right:20px;
                        }
                    }
                }
            }
        }
        .tab-content{
            min-height:650px;
            @media #{$medium}{
                min-height: auto;
            }
            @media #{$mobileLarge}{
                min-height:800px;
            }
            #nav-logowanie{
                width:100%;
                height:650px;
                @media #{$mobileLarge}{
                    height:800px;
                }
                @media #{$medium}{
                    background:none;
                }
                background:url('/img/intro/img.png') no-repeat center left;
                background-size: 60%;
            }
            .alert{
                position: absolute;
                width: calc(100% - 30px);
                top: 40px;
                left: 50%;
                transform: translate(-50%);
            }
            form{
                height:650px;
                @media #{$mobileLarge}{
                    height:800px;
                    margin-top:40px;
                }
                @media #{$mobile}{
                    min-height:900px;
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .pretty--longText .state a{z-index:9999; position:relative; font-weight: normal;}
        }
    }
    h2{
        font-size:4.2em;
        font-weight: bold;
        margin-top: 60px;
        margin-bottom: 20px;
    }

}

.required-text{
    font-size: 1em;
}

.form-group{
    ul{
        list-style: none;
        padding: 8px;
        color: maroon;
        background-color: #fdcccc;
        border-color: #fcb8b8;
    }
}
.user-login{
  
}