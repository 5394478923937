.top{
    svg{
        #highlight{
            image{
                display: none;
                overflow: visible;
                opacity: 0.8;
                &.active,
                &.activeHover{
                    display:block;
                }
            }
        }
        #mapa-klikalna{
            *{
                fill:rgba(255,255,255,0.1);
                @include transition(all .1s ease);
            }
            *:hover{
                cursor:pointer;
            }
        }
    }
}