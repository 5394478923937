.box{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item{      
        color:$black;  
        border:1px solid $lightGray;
        display: flex;
        flex-direction: column;
        min-height:200px;
        justify-content: flex-end;
        margin-top:0;
        position: relative;
        margin-bottom:25px;
        @include transition(all .1s ease);
        &:hover{
            margin-top:-10px
        }
        .linkBox{
            background-color:$bgLightGray;
            padding:10px 15px;
            span{
                font-weight: bold;
                color:$black;
                font-size: 1.4em;
                display: flex;
                justify-content: space-between;
                img{
                    transform:rotate(180deg);
                }
            }
            &-product{
                background: $yellow;
                text-transform: uppercase;
                padding: 17px;
                text-align: center;
                a{
                    display: inherit;
                    text-decoration: none;
                    font-weight: 500;
                }
            }
        }
        span{
            text-align:center;
            font-size: 1.8em;
            font-weight: 600;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            padding: 10px;
            &.icon{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 10px;
                left: 10px;
            }
            &.excerpt{
                font-size: 1.2em;
                margin-top: -10px;
            }
            &.description{
                position: absolute;
                bottom: 60px;
                left: 0;
                height: auto;
                background: #e5e5e5;
                width: 40%;
                padding: 0;
                padding-left: 20px;
                justify-content: flex-start;
                font-size: 1.6em;
                p{
                    font-weight: bold;
                    margin: 2px 2px 8px 2px;
                    line-height: 1.6em;
                    padding-top: 8px;
                }
            }
        }
        .title{
            font-size: 2.2em;
            text-align: center;
            margin: 1.1em 1.3em 0.2em;
            border-bottom: 1px solid $bckGrey;
            padding-bottom: 1em;
            font-weight: 500;
        }
        .image{
            align-items: center;
            padding: 30px;
            &--products {
                height: 300px;
                img {
                    height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    &-2{
        .item{
            width:49%;
            @media #{$mobile}{
                width:100%;
            }
        }
    }
    &-3{
        .item{
            width:30%;
            margin: 10px 10px;
            @media #{$res}{
                width:45%;
            }
            @media #{$mobile}{
                width:100%;
            }
        }
    }
    &-4{
        .item{
            width:24%;
            @media #{$res}{
                width:45%;
            }
            @media #{$mobile}{
                width:100%;
            }
        }
    }

}